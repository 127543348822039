import { UrlObject } from 'url'

export const queryParams = {
  step: 'step',
  backUrl: 'back-url',
  origin: 'origin',
}

export const pathConstructor = {
  step: (step: string): UrlObject => ({
    pathname: '/registration',
    query: {
      [queryParams.step]: step,
    },
  }),
  index: ({
    origin,
    backUrl,
  }: { origin?: string; backUrl?: string } = {}): UrlObject => ({
    pathname: '/registration',
    query: {
      [queryParams.origin]: origin,
      [queryParams.backUrl]: backUrl,
    },
  }),
}
