import { useEffect, useRef } from 'react'
import * as React from 'react'
import View from '@packages/common/src/components/View'
import Heading from '@packages/common/src/components/Heading'
import FormTextInput from '@packages/common/src/components/TextInput/FormTextInput'
import { SubmitButton } from '@packages/common/src/components/Button'
import Text from '@packages/common/src/components/Text'
import FormEnhanced from '@packages/common/src/components/FormEnhanced'
import { useTranslation } from 'react-i18next'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import Link from '@apps/accounts/src/components/Link'
import { pathConstructor as registrationPathConstructor } from '@apps/accounts/src/pages/registration.helpers'

type Props = {
  origin: string
  backUrl: string
}

const styles = cmpStyles({
  box: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  spaces: {
    flexDirection: 'column',
    alignSelf: 'center',
    width: 300,
    nested: {
      '&>:nth-child(1)': {
        marginBottom: 20,
      },
      '&>:nth-child(2)': {
        marginBottom: 15,
      },
      '&>:nth-child(3)': {
        marginBottom: 5,
      },
      '&>:nth-child(4)': {
        marginBottom: 20,
      },
      '&>:nth-child(5)': {
        marginBottom: 25,
      },
      '&>:nth-child(7)': {
        marginBottom: 25,
      },
    },
  },
  link: {
    alignSelf: 'flex-start',
  },
})

const FormPage = ({ origin, backUrl }: Props) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // force focus
    inputRef.current?.focus()
  }, [])

  return (
    <View style={styles.box}>
      <View as={FormEnhanced} style={styles.spaces} testID="login-form">
        <Heading align="center">{t('sign-in.form.title')}</Heading>

        <FormTextInput
          ref={inputRef}
          name="email"
          placeholder={t('sign-in.form.email-placeholder')}
          autoFocus
          maxLength={255}
        />

        <FormTextInput
          type="password"
          name="password"
          placeholder={t('sign-in.form.password-placeholder')}
          maxLength={255}
        />

        <Link
          weight="bold"
          to="/reset-password"
          accent="default"
          style={styles.link as any}
          scale={-1}>
          {t('sign-in.form.forgot-password')}
        </Link>

        <SubmitButton accent="secondary">
          {t('sign-in.form.submit')}
        </SubmitButton>

        <Text scale={-1}>{t('sign-in.want-register')}</Text>

        <View>
          <Link
            to={registrationPathConstructor.index({ origin, backUrl })}
            weight="bold"
            scale={-1}
            accent="default">
            {t('sign-in.register-link')}
          </Link>
        </View>
      </View>
    </View>
  )
}

export default FormPage
